import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default function SuccessPage () {
  return (
    <Layout>
      <SEO title='Thank you' />
      <div style={{ textAlign: 'center' }}>
        <h1>Registration Successful</h1>
        <div>
          I look forward to seeing you soon.
        </div>
      </div>
    </Layout>
  )
}
